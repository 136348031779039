import { AxiosAPIClient, AxiosAuthClient, X_API_KEY } from "../constants";

// Cache the authorization code, but expire after 1 minute in
// case user logs out in another window.
const AUTH_EXPIRATION_SECS = 60;
let cached_authorization = false;
let cached_authorization_expires = Date.now() + (1000 * AUTH_EXPIRATION_SECS);
let tokenRequest;

AxiosAPIClient.interceptors.request.use(
	async config => {
		if (cached_authorization && cached_authorization_expires > Date.now()) {
			config.headers.Authorization = cached_authorization || '';
			return config;
		}

		if (X_API_KEY) {
			config.headers['x-api-key'] = X_API_KEY;
			return config;
		}

		tokenRequest ??= AxiosAuthClient.post("getToken");

		const response = await tokenRequest;
		const token = response.data.token;

		config.headers.Authorization = token;
		cached_authorization = token || '';
		cached_authorization_expires = Date.now() + (1000 * AUTH_EXPIRATION_SECS);

		Object.keys(config.headers).forEach(key => {
			if (config.headers[key] === undefined) {
				delete config.headers[key];
			}
		});

		return config;
	},
	error => Promise.reject(error)
);
AxiosAPIClient.interceptors.response.use(
	response => response,
	error => {
		if (error.response?.status !== 401) {
			return Promise.reject(error);
		}
		localStorage.removeItem('catalog_invitation:data');
		localStorage.removeItem("organization_member:data");
		localStorage.removeItem("currentUser");
		window.location = '/login?redirect=' + window.location.pathname;
		return Promise.reject(error);
	}
);


export default AxiosAPIClient;
