import ApiDrivenModel from "./ApiDrivenModel";

class Profile extends ApiDrivenModel {
	static async GetCurrent(user, apiContext) {
		if (Profile.Current) {
			return Profile.Current;
		}

		const result = await Profile.FetchBy({
			filter: [{ property: 'username', value: user.username, }],
			paths: Profile.LOAD_PATHS,
		});
		Profile.Current = result;

		return Profile.Current;
	}
}
Profile.ENDPOINT = "/Profile";
Profile.SEARCH_PATHS = {
    id: true,
    email: true,
    family_name: true,
    given_name: true,
    organization: [
        'id',
        'name',
		'logo',
		'branding'
    ],
    organization_name: true
};
Profile.LOAD_PATHS = {
    id: true,
    email: true,
    family_name: true,
    given_name: true,
    organization: [
        'id',
        'name',
		'logo',
		'branding'
    ],
    organization_name: true
};

export default Profile;
