import ApiDrivenModel from "./ApiDrivenModel";

class Item extends ApiDrivenModel {
	constructor(properties) {
		super(properties);
		delete this.data; // prevent circular serialization error with JSONing later
	}

	getMeta() {
		return {
			handle: this.common_sku,
			title: this.name,
			description: this.description,
			image: this.media?.find((m) => m.type === "image")?.source
		}
	}

	static PATHS = {
		id: true,
		common_sku: true,
		name: true,
		description: true,
		image_0: true,
		image_1: true,
		image_2: true,
		image_3: true,
		image_4: true,
		video_0: true,
		video_1: true,
		video_2: true,
		video_3: true,
		video_4: true,
		sell_price: true,
		pdf: true,
		gemstone: true,
	};
}

export default Item;